<template>
  <button :class="{'disabled': disabled}" icon-button>
    <img v-if="src" :src="src" alt="icon" class="icon">
    <slot name="icon"></slot>
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    src: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[icon-button] { .flex; .items-center; .bgc(#fff); border: solid 1px #d5d5de; .p(4, 12); .br(4); .pointer; .fs(12); .c(#787781); box-sizing: border-box; .min-h(32);
  &:hover { border: solid 1px #191919; .c(#191919); }
  .icon { .wh(24); .mr(4); }
  &.disabled { .bgc(#f2f2f4); pointer-events: none; }
}
</style>
