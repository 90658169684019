<template>
  <!-- web semantic 에 맞게 quote: q 태그 사용 -->
  <q coach-info-banner :data-theme="theme">
    <svg-info class="icon-info" />
    <div class="banner-contents">
      <slot></slot>
    </div>
  </q>
</template>

<script>
import SvgInfo from '@/views/graphics/svg-info.vue';

export default {
  name: 'CoachInfoBanner',
  components: { SvgInfo },
  props: {
    theme: {
      type: String,
      default: 'base',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-info-banner] {
  &[data-theme='base'], &[data-theme='base-inline'] {.flex;.p(20);.bgc(#f2f5ff);.br(8);border: 1px solid #c6d3ff;

    .icon-info {.min-w(20);.min-h(20);}

    .banner-contents {.c(#191919);.ml(12);.lh(21);.fs(14);
      p {.m(0);}
      a {.flex;.items-center;.w(fit-content);.c(#2c81ff);text-decoration: none;.bold;
        > svg {.ml(4);}
      }
    }
  }
  &[data-theme='base-inline'] {display: inline-flex;}
  &[data-theme='grey'] {
    svg {.hide}
    .banner-contents { .bgc(#f7f7f7);.p(8);.fs(12);.lh(17);.c(#444); }
  }
}

</style>
