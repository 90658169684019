<template>
  <IconButton :disabled="disabled" @mouseleave.native="isHover = false" @mouseover.native="isHover = true" button-download>
    <template #icon>
      <SvgDownload :fill="isHover ? '#191919' : '#a4a3ae'" class="icon"/>
    </template>
    <template>
      <slot></slot>
    </template>
  </IconButton>
</template>

<script>
import IconButton from '@/views/components/coaching/IconButton.vue';
import SvgDownload from '@/views/graphics/svg-download.vue';

export default {
  name: 'ButtonDownload',
  props: { disabled: { type: Boolean, default: false } },
  data: () => ({
    isHover: false
  }),
  components: { SvgDownload, IconButton }
};
</script>