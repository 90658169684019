<template>
  <div :data-disabled="disabled" coach-search-input>
    <TextInput v-model="_value" :class="{'error': hasError }" :disabled="disabled" :placeholder="placeHolder" class="search-input" @enter="$emit('search');" />
    <SvgSearch :height="24" :width="24" alt="icon-search" class="search-icon" fill="#A4A3AE" />
    <svg-warn v-show="hasError" alt="icon-warn" class="warn-icon" />
    <button v-show="_value" class="cancel-btn" @click="clearBtnHandler">
      <svg-cancel alt="icon-cancel" class="cancel-icon" />
    </button>
  </div>
</template>

<script>

import TextInput from 'shared/components/common/input/TextInput.vue';
import SvgWarn from '@/views/graphics/svg-warn.vue';
import SvgSearch from '@/views/graphics/svg-search.vue';
import SvgCancel from '@/views/graphics/svg-cancel.vue';

export default {
  name: 'CoachSearchInput',
  components: { SvgWarn, TextInput, SvgCancel, SvgSearch },
  props: {
    value: { type: String, default: '' },
    placeHolder: { type: String, default: '' },
    hasError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    clearBtnHandler() {
      this._value = '';
      this.$emit('search');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-search-input] { .flex; .rel; .w(100%); .h(44);
  > fieldset {.w(100%); }
  .search-input {.w(100%); .h(100%); border: 1px solid #d5d5de;
    > input {.h(44); .p(0, 68, 0, 40); .bgc(#fff); .fs(14); font-weight: normal; }
    &:after { display: none; }
    &.error { border: 1px solid #f00; }
  }
  .warn-icon {.abs; .t(10); .r(40); pointer-events: none; .z(1); }
  .search-icon { .abs; .t(10); .l(12); pointer-events: none; .z(1); }
  .cancel-btn { .abs; .t(10); .r(12); .z(1); .w(24); .h(24);}
  .search-input > input:disabled {.bgc(#f2f2f4);}
}
</style>
