<template>
  <svg fill="none" :height="height" viewBox="0 0 24 24" :width="width" xmlns="http://www.w3.org/2000/svg">
    <path :fill="fill" clip-rule="evenodd" d="M12.6722 3L5.12976 5.02175L5.04313 5.04425L3 12.6734L8.52297 18.1941L8.58597 18.256L15.3971 16.4311L19.9672 21.0011L21 19.9661L16.4322 15.3971L18.2571 8.58372L12.7352 3.063L12.6722 3ZM4.58412 12.2481L6.20535 6.2042L12.2492 4.5841L16.6741 9.00787L15.0529 15.054L9.00903 16.6741L4.58412 12.2481Z" fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgSearch',
  props: {
    fill: { type: String, default: '#fff' },
    width: { type: Number, default: 16 },
    height: { type: Number, default: 16 }
  },
};
</script>
