<template>
  <Selector :value="value" :class="[direction]" :disabled="disabled" :list="list" :placeholder="placeholder" :subLabel="subLabel" coach-dropdown @change="changeHandler" />
</template>

<script>
import Selector from '@shared/components/common/Selector.vue';

export default {
  name: 'CoachDropdown',
  components: { Selector },
  props: {
    disabled: { type: Boolean, default: false },
    list: { type: Array, default: () => ([]) },
    subLabel: { type: String, default: '' },
    placeholder: { type: String, default: null },
    value: { type: [String, Number], default: null },
    direction: { type: String, default: 'down' },
  },
  methods: {
    changeHandler(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-dropdown] {
  > div { .br(8);
    > a > input { .fs(14); .noto; .pointer }
    > a > label { .fs(14); .c(#a4a3ae); .pointer;}
    &:hover { border: solid 1px #191919;}
    &.open { border: solid 1px #191919; border-radius: 8px 8px 0px 0px;
      > ul { border: solid 1px #191919; box-sizing: content-box; .l(-1); .t(-2); border-radius: 0 0 8px 8px;
        li { .fs(14);
          &.selected { .bgc(rgba(164, 163, 174, 0.3)); }
        }
      }
    }
  }
  &.disabled { pointer-events: none;}
  &.down { }
}

</style>
