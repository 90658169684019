<template>
  <div table-control>
    <p v-html="totalCountText" class="text-info" />
    <slot></slot>
    <transition name="fade">
      <p v-show="selectedCount > 0" v-html="selectedCountText" class="text-info-selected"></p>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    totalCount: {
      type: Number,
      default: 0,
    },
    selectedCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    totalCountText() {
      return this.$t('coaching.settlementHistory.totalCount', [this.totalCount]);
    },
    selectedCountText() {
      return this.$t('coaching.settlementHistory.selectedCount', [this.selectedCount]);
    },
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';
[table-control] {.flex;.mt(32);flex-flow: row wrap;
  .text-info {flex: 1;.fs(14);.c(#787781);display: inline-flex;.flex-ai(center);}
  .text-info > em {.c(#191919);.ml(4);}
  .text-info-selected {.w(100%);.tr;.mt(14);.mr(8);.c(#8300ff);.fs(12);transition: opacity .5s;}
  [button-download] + [button-download] {.ml(8)}

  .fade-enter-to {
    opacity: 1;
    transition: all 0.3s ease;
  }
  .fade-enter-active {
    transition: all 0.8s;
  }
  .fade-enter {
    opacity: 0;
  }
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
